<template>
  <div class="MyConsigneeInfo">
    <span class="addBtn" :span="16" @click="addMyConsignee">
      新增收货地址
    </span>
    <div :gutter="20" class="item" v-if="consigneeList.length > 0">
      <el-col
        :span="8"
        class="itemBox"
        v-for="(item, index) in consigneeListArr"
        :key="index"
      >
        <div
          class="itemContent"
          :class="{ active: Boolean(activeFlag === index) }"
          @click="checkFun(index,item)"
        >
          <p><span class="TitLabel">收货人: </span> <span>{{item.name}}</span></p>
          <p><span class="TitLabel">手机号: </span> <span>{{item.phone}}</span></p>
          <p><span class="TitLabel">地址: </span> <span>{{item.address}}</span></p>
          <div class="btnBox">
            <span  class="edit" @click="editMyConsignee(item)"
              >编辑</span
            >
            <span v-if="item.isDefault==1">默认</span>
          </div>
        </div>
      </el-col>
      <div class="clear"></div>
      <p v-if="moreFlag && consigneeList.length>3" class="moreBtn" @click="moreFun">
        更多地址 <strong class="el-icon-arrow-down"></strong>
      </p>
      <p v-if="!moreFlag" class="moreBtn" @click="packupFun">
        收起 <strong class="el-icon-arrow-up"></strong>
      </p>
    </div>
    <el-dialog :title="titleMsg" :visible.sync="dialogFormVisible" width="24%"  :before-close="handleClose">
        <el-form  
         :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
         
        >
            <el-form-item label="收货人"    prop="name">
                <el-input v-model="ruleForm.name"  ref="name" autocomplete="off"  placeholder="请输入收货人" autofocus></el-input>
            </el-form-item>
            <el-form-item label="手机号"   prop="mobile">
                <el-input v-model.number="ruleForm.mobile" ref="mobile" autocomplete="off"  placeholder="请输入手机号码"></el-input>
            </el-form-item>
            <el-form-item label="详细地址"  prop="address">
                <el-input type="textarea" v-model="ruleForm.address" ref="address" autocomplete="off"  placeholder="请输入详细地址"></el-input>
            </el-form-item>
            <el-form-item label="是否默认地址" prop="isDefaultAddress">
                <el-switch v-model="ruleForm.isDefaultAddress"></el-switch>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button  @click="saveFun('ruleForm')">保存收货地址</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import Qs from "qs";
import { GetDefaultConsigneeList,GetConsigneeList ,AddConsignee,editConsignee} from "api/carToOrder.js";
export default {
  name: "DeliveryInfo",
   inject:['reload'],
  data() {
     //表单验证
    let validateName = (rule, value, callback) => {
      if (!value ) {
        callback(new Error("请输入收货人"));
      } else {
        callback();
      }
    };

      let validateMobile = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      }else{
          if (!Number.isInteger(value)) {
            callback(new Error("请输入数字值"));
          } else {
            if (!/^1[3456789]\d{9}$/.test(value)) {
              callback(new Error("请输入正确的手机号码!"));
            } else {
              callback();
            }
          }
      }
      
    };
    let validateAddress = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入地址信息"));
      } else {
        callback();
      }
    };
    return {
      moreFlag: true,
      activeFlag: 0, //默认选中第一个,
      consigneeList: [],
      consigneeListArr:[],
      titleMsg: "",
      dialogFormVisible: false, //初始新增收货信息弹框为不显示
      ruleForm: {
          name: '',
          mobile: '',
          address: '',
          isDefaultAddress:false  //状态 false 0：不默认 || true 1：默认
        },
        rules: {
        name:[{required:true,message:"收货人必填", trigger: "blur"},{ validator: validateName, trigger: "blur" }],
        mobile: [{required:true,message:"手机号必填", trigger: "blur"},{ validator: validateMobile, trigger: "blur" }],
        address: [{required:true,message:"地址必填", trigger: "blur"},{ validator: validateAddress, trigger: "blur" }],
      },
    };
  },
  props: {
    goodsList: {
      type: Object,
    },
  },
  computed: {},
  created() {
     this.getConsigneeList();
    
  },
  methods: {
    // 关闭弹窗事件
     handleClose() {
          if(this.consigneeList.length>0){
              this.dialogFormVisible = false;
          }else{
            // 跳回购物车
            this.$router.push({ name: "Cart" });
          }
      },
    // 点击更多地址
    moreFun() {
      this.moreFlag = false;
      this.consigneeListArr = this.consigneeList;
    },
    // 
    packupFun() {
      this.moreFlag = true;
       this.consigneeListArr = this.consigneeList.slice(0, 3);
    },
    // 点击选中事件
    checkFun(index,item) {
      this.activeFlag = index;
      this.goodsList.address=item.address
      this.goodsList.consigneeName=item.name
      this.goodsList.consigneeTel=item.phone
    },
    //   新增收货地址
    addMyConsignee() {
      this.dialogFormVisible = true;
      this.titleMsg = "新增收货地址";
    },
    // 编辑收货地址弹框
    editMyConsignee(item){
        this.dialogFormVisible = true;
        this.titleMsg="编辑收货地址"
        this.ruleForm.address=item.address
        this.ruleForm.name=item.name
        this.ruleForm.mobile=Number(item.phone),
        this.editId=item.id
        if(item.isDefault==1){
            this.ruleForm.isDefaultAddress=true
        }else{
            this.ruleForm.isDefaultAddress=false
        }
    },
    // 修改编辑地址信息
editConsigneeFun(memberStoreAddress){
    editConsignee(memberStoreAddress).then(data=>{
                        console.log(data)
                        this.dialogFormVisible = false;
                        if(data.data.code===400){
                            this.$message.error(data.data.msg);
                            this.ruleForm.name='';
                            this.$nextTick(() => {
                                this.$refs.name.focus()
                            },100)
                            return
                        }else{
                            this.$message({
                                message:'编辑地址成功',
                                type: 'success'
                            });
                            this.reload();
                        }
                    })
},
  // 新增编辑地址信息
  AddConsigneeFun(memberStoreAddress){
        AddConsignee(memberStoreAddress).then(data=>{
                        console.log(data)
                        this.dialogFormVisible = false;
                        if(data.data.code===400){
                            this.$message.error(data.data.msg);
                            this.ruleForm.name='';
                            this.$nextTick(() => {
                                this.$refs.name.focus()
                            },100)
                            return
                        }else{
                            this.$message({
                                message:"新增地址成功",
                                type: 'success'
                            });
                            this.reload();
                        }
                    })
  },

    // 保存修改
    saveFun(formName){
        this.$refs[formName].validate((valid) => {
            if (valid) {
                let memberStoreId=Qs.parse(sessionStorage.getItem("memberData")).memberStoreId
                let isDefault=this.ruleForm.isDefaultAddress
                if(isDefault){
                    isDefault=1
                }else{
                    isDefault=0
                }
                if(this.titleMsg=="编辑收货地址"){
                     let memberStoreAddress={
                         'id': this.editId,
                        'memberStoreId':memberStoreId,
                        'address':this.ruleForm.address,
                        'name':this.ruleForm.name,
                        'phone':this.ruleForm.mobile,
                        'isDefault':isDefault
                    }
                   this.editConsigneeFun(memberStoreAddress)
                }else{
                    let memberStoreAddress={
                        'memberStoreId':memberStoreId,
                        'address':this.ruleForm.address,
                        'name':this.ruleForm.name,
                        'phone':this.ruleForm.mobile,
                        'isDefault':isDefault
                    }
                    this.AddConsigneeFun(memberStoreAddress)
                }
            } else {
                return false;
            }
      });
     },

     
    getConsigneeList() {

      //获取默认地址接口 GetDefaultConsigneeList
      GetDefaultConsigneeList().then(res=>{
        if(res.data.code==200){
                  let isEmpty = (obj) => (JSON.stringify(obj) === '{}') ? true : false
                  if(isEmpty(res.data.data)){
                    // 默认地址为空，此时获取列表
                     // 获取收货地址列表
                      GetConsigneeList().then(res=>{
                            if(res.data.data.records.length==0){
                                  // alert("此时无收货地址")    出提示
                                this.$confirm('还没有收货地址,请设置收货地址?', '提示', {
                                  confirmButtonText: '去设置',
                                  cancelButtonText: '取消',
                                  type: 'warning'
                                }).then(() => {
                                    this.addMyConsignee()
                                }).catch(() => {

                                  // this.$message({
                                  //   type: 'info',
                                  //   message: '已取消'
                                  // });

                                   this.$router.push({ name: "Cart" });
                                });
                            }else{
                                // 收货信息赋值
                                this.consigneeList = res.data.data.records
                                this.consigneeListArr = this.consigneeList.slice(0, 3);

                                // 初始收货地址
                                this.goodsList.address=this.consigneeListArr[0].address
                                this.goodsList.consigneeName=this.consigneeListArr[0].name
                                this.goodsList.consigneeTel=this.consigneeListArr[0].phone
                            }
                            
                      })
                  }else{
                      // 初始默认收货地址
                      this.goodsList.address=res.data.data.address
                      this.goodsList.consigneeName=res.data.data.name
                      this.goodsList.consigneeTel=res.data.data.phone
                      // 获取收货地址列表
                      GetConsigneeList().then(res=>{
                            // 收货信息赋值
                          this.consigneeList = res.data.data.records
                            this.consigneeListArr = this.consigneeList.slice(0, 3);
                      })
                  }
                  
        }else{
          // 获取不到默认地址信息
          // 获取收货地址列表
            GetConsigneeList().then(res=>{
                  // 收货信息赋值
                this.consigneeList = res.data.data.records
                  this.consigneeListArr = this.consigneeList.slice(0, 3);

                  // 初始收货地址
                  this.goodsList.address=this.consigneeListArr[0].address
                  this.goodsList.consigneeName=this.consigneeListArr[0].name
                  this.goodsList.consigneeTel=this.consigneeListArr[0].phone
            })
        }
      })
       
    },
  },
};
</script>

<style lang="less" scoped>
@import "~style/index.less";
.moreBtn {
  line-height: 70px;
  color: #666;
  font-size: 14px;
  padding-left: 6px;
  cursor: pointer;
}
.el-col/deep/ {
  padding: 0px;
}
/deep/.el-dialog__header {
  background: #ececec;
  padding: 5px 10px;
  .el-dialog__title {
    font-size: 14px;
    font-weight: bold;
  }
  .el-dialog__headerbtn {
    top: 8px;
  }
}
/deep/.el-input__inner {
  line-height: 33px;
  width: 50%;
}
/deep/.el-dialog__body {
  padding: 22px 40px 0 40px;
}
/deep/.el-form-item__label {
  width: 100%;
  text-align: left;
  line-height: 25px;
}
/deep/.dialog-footer {
  text-align: left;
  padding: 0px 20px 20px;
  .el-button {
    background: #ff3333;
    color: #fff;
    &:hover {
      background: #ff3333;
      color: #fff;
    }
  }
}
.clear {
  clear: both;
}

.MyConsigneeInfo {
  position: relative;
  .addBtn {
    color: #409fe0;
    font-size: 13px;
    position: absolute;
    top: -38px;
    right: 20px;
    cursor: pointer;
  }
}
.item {
  padding: 0 20px 20px 20px;
  border: 1px solid #f0f0f0;
  border-top: none;
  border-bottom: none;

  .itemBox {
    padding: 0 5px;
    .itemContent {
      border: 2px solid #f0f0f0;
      padding: 15px 20px;
      margin-bottom: 10px;
      cursor: pointer;
      &:hover {
        border: 2px solid #ff3333;
        background: url("../../assets/checkedRed.png") no-repeat right bottom;
        background-size: 26px 26px;
        p {
          .TitLabel {
            color: #333;
          }
          span:nth-child(2) {
            color: #333;
          }
        }
      }
      &.active {
        border: 2px solid #ff3333;
        background: url("../../assets/checkedRed.png") no-repeat right bottom;
        background-size: 26px 26px;
        p {
          .TitLabel {
            color: #333;
          }
          span:nth-child(2) {
            color: #333;
          }
        }
      }
      .btnBox {
        font-size: 14px;
        color: #666666;
        line-height: 20px;
        margin-top: 12px;
        position: relative;
        z-index: 9;
        span:first-child {
          color: #409fe0;
          cursor: pointer;
        }
        span:nth-child(2) {
          float: right;
          background: #666;
          color: #fff;
          padding: 0 5px;
        }
      }
      p {
        font-size: 14px;
        color: #666666;
        line-height: 20px;
        margin-top: 12px;
        .TitLabel {
          text-align: justify;
          text-align-last: justify;
          text-justify: distribute-all-lines;
          width: 48px;
          padding-right: 10px;
          color: #ccc;
          display: inline-block;
        }
        span:nth-child(2) {
          color: #666;
        }
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
